
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class J1LMenu extends Vue {
  /* Properties */
  @Prop({ required: true }) options!: any[];
  @Prop() target!: any;
  @Prop({ default: 'mdi-dots-horizontal' }) menuIcon!: string;
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Utility Functions */
  handleOptionClick(option: any) {
    this.$emit('optionSelected', { option: option, target: this.target });
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
