
import { ProcessErrorReportModel } from '@/store/models/AmazonAccessModels';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { AmazonAccessService } from '@/services/amazon-access-service';
@Component
export default class extends Vue {
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  errorReportId: number = 0;
  report: ProcessErrorReportModel = new ProcessErrorReportModel();
  /* Async Functions */
  /* Utility Functions */
  openDialog() {
    this.dialog = true;
  }
  async setData(params: any) {
    this.errorReportId = params;
    const { data } = await AmazonAccessService.GetProcessErrorReport(this.errorReportId);
    this.report = new ProcessErrorReportModel(data);
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
