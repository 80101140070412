import axios from 'axios';

const amazonAccessUrl = `${process.env.VUE_APP_ROOT_API}/AmazonAccess`;

export const AmazonAccessService = {
  GetOrderLinesForTransparencyAdmin: function() {
    return axios.get(`${amazonAccessUrl}/GetOrderLinesForTransparencyAdmin?viewOption=1`);
  },
  GetAmazonRequest: function(orderlineId: string) {
    return axios.get(`${amazonAccessUrl}/GetAmazonRequest?orderlineId=${orderlineId}`);
  },
  RequestCodesFromAmazon: function(model: any) {
    return axios.post(`${amazonAccessUrl}/RequestCodesFromAmazon`, model);
  },
  SendNeedCodesEmail: function(model: any) {
    return axios.post(`${amazonAccessUrl}/SendNeedCodesEmail`, model);
  },
  RollbackVarData: function(orderLineId: string) {
    return axios.get(`${amazonAccessUrl}/RollbackVarData?orderlineId=${orderLineId}`);
  },
  SendMultipleTransparencyErrorEmails: function(model: any) {
    return axios.post(`${amazonAccessUrl}/SendMultipleTransparencyErrorEmails`, model);
  },
  UploadCodeFile(formData: FormData, config: any = null) {
    return axios.post(`${amazonAccessUrl}/UploadVarDataFile`, formData, config);
  },
  ValidateCodes(
    fileName: string,
    expectedQuantity: number,
    originalFileName: string,
    customerId: string
  ) {
    return axios.get(
      `${amazonAccessUrl}/ValidateCodeFile?codeFileName=${fileName}&orderQuantity=${expectedQuantity}&originalFileName=${originalFileName}&customerId=${customerId}`
    );
  },
  SaveBarcodes(model: any, config: any = null) {
    return axios.post(`${amazonAccessUrl}/SaveBarcodeValues`, model, config);
  },
  ResetCodeRequest(orderLineId: string) {
    return axios.post(`${amazonAccessUrl}/ResetCodeRequest?orderLineId=${orderLineId}`);
  },
  GetAmazonProcessErrors(orderLineId: string) {
    return axios.get(
      `${amazonAccessUrl}/GetAmazonRequestErrors?orderLineId=${orderLineId}`
    );
  },
  RemoveCodeRequest(orderLineId: string) {
    return axios.post(
      `${amazonAccessUrl}/RemoveAmazonRequest?orderLineId=${orderLineId}`
    );
  },
  async GetProcessErrorReport(errorReportId: number) {
    return axios.get(
      `${amazonAccessUrl}/GetProcessErrorReport?errorReportId=${errorReportId}`
    );
  }
};
