import { DatePickerMultipleFormatter } from 'vuetify';
import { Orderline } from './OrderlineModel';

export class CodeRequestModel {
  ASIN!: string;
  CanImportData: boolean = false;
  CanRequestCodes!: boolean;
  CanRollback!: boolean;
  Customer!: string;
  CustomerId!: string;
  Description!: string;
  DueDate!: string;
  GTIN!: string;
  GTINType!: string;
  HasFailedRequest!: boolean;
  HasSentErrorEmail!: boolean;
  ErrorReportId!: number;
  Id!: string;
  IsSelected!: boolean;
  Options!: any[];
  OrderlineStatus!: string;
  PartNumber!: string;
  Quantity!: number;
  SKU!: string;
  HasNoCodeRequestError!: boolean;
  ProcessStatus!: string;
  IsAPIRequest!: boolean;
  HasStatusError!: boolean;
  ProcessStatusCode!: number;
  HasError!: boolean;
  OriginalOrderline!: Orderline;
  ShouldDelayProcess!: boolean;
  DelayProcessInMinutes!: number;
  public get IsMissingData() {
    return !(this.SKU && this.ASIN && this.PartNumber && this.GTIN);
  }
  constructor(rawItem?: Orderline | any) {
    this.Options = [];
    this.IsSelected = false;
    if (rawItem && (<Orderline>rawItem).Item) {
      this.PopulateFromOrderline(rawItem);
    } else {
      this.PopulateGeneric(rawItem);
    }
  }
  PopulateGeneric(rawItem?: any) {
    if (rawItem) {
      this.CanImportData = rawItem.CanImportData;
      this.CanRequestCodes = rawItem.CanRequestCodes;
      this.CanRollback = rawItem.CanRollback;
      this.Customer = rawItem.CustomerName;
      this.CustomerId = rawItem.CustomerId;
      this.Description = rawItem.ItemDesc;
      this.DueDate = rawItem.DueDate;
      this.GTIN = rawItem.Gtin;
      this.GTINType = rawItem.GtinType;
      this.HasError = rawItem.HasError;
      this.HasFailedRequest = rawItem.HasFailedRequest;
      this.HasSentErrorEmail = rawItem.HasSentErrorEmail;
      this.ErrorReportId = rawItem.ErrorReportId;
      this.Id = rawItem.Id;
      this.OrderlineStatus = rawItem.OrderlineStatus;
      this.ASIN = rawItem.Asin;
      this.PartNumber = rawItem.PartNumber;
      this.Quantity = rawItem.Quantity;
      this.SKU = rawItem.Sku;
      this.ProcessStatus = rawItem.ProcessingStatus;
      this.HasNoCodeRequestError = rawItem.HasNoCodeRequestError;
      this.IsAPIRequest = rawItem.IsAPIRequest;
      this.HasStatusError = rawItem.HasStatusError;
      this.ProcessStatusCode = rawItem.ProcessStatusCode;
    }
  }
  PopulateFromOrderline(rawItem: Orderline) {
    this.PartNumber = rawItem.Item.PartNumber;
    this.Description = rawItem.Item.Description;
    this.Quantity = rawItem.Quantity;
    this.Id = rawItem.Id;
    this.OriginalOrderline = rawItem;
  }
}

export class SubmitCodesModel {
  ConsumeId!: number;
  OrderLineId!: string;
  ShouldDelayProcess!: boolean;
  ProcessDelayInMinutes!: number;
}

export class TransparencyError {
  FulfillmentType!: number; // 0 is file and 1 is api
  HasAnError!: boolean;
  HasSentErrorEmail!: boolean;
  ErrorType!: number; // 0 is processing error 1 is fufillment error
  ErrorReportId!: number;
  ProcessingStatusErrorCode!: number; // -1 has no processing error.
  constructor(rawItem: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.FulfillmentType = rawItem.FulfillmentType;
      this.HasAnError = rawItem.HasAnError;
      this.HasSentErrorEmail = rawItem.HasSentErrorEmail;
      this.ErrorType = rawItem.ErrorType;
      this.ErrorReportId = rawItem.ErrorReportId;
      this.ProcessingStatusErrorCode = rawItem.ProcessingStatusErrorCode;
    }
  }
}

export class AdminCodeModel {
  Id!: string;
  ItemId!: string;
  ApprovalStatus!: string;
  CustomerName!: string;
  CustomerId!: string;
  DueDate!: string;
  ItemDesc!: string;
  PartNumber!: string;
  Sku!: string;
  Asin!: string;
  Gtin!: string;
  GtinType!: string;
  VarDataDynamicTextType!: string;
  Status!: string;
  RawStatus!: string;
  Quantity!: number;
  VarDataStatus!: string;
  CanImportData!: boolean;
  CanRollback!: boolean;
  TransparencyError!: TransparencyError;
  ProcessStatusCode!: number; // -1 is no code.
  CanRequestCodes!: boolean;
  NeedsCodes!: boolean;
  HasSentErrorEmail!: boolean;
  FulfillmentType!: number; // 0 is file 1 is api
  constructor(rawItem: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.ItemId = rawItem.ItemId;
      this.ApprovalStatus = rawItem.ApprovalStatus;
      this.CustomerName = rawItem.CustomerName;
      this.CustomerId = rawItem.CustomerId;
      this.ItemDesc = rawItem.ItemDesc;
      this.PartNumber = rawItem.PartNumber;
      this.DueDate = rawItem.DueDate;
      this.Sku = rawItem.Sku;
      this.Asin = rawItem.Asin;
      this.Gtin = rawItem.Gtin;
      this.GtinType = rawItem.GtinType;
      this.VarDataDynamicTextType = rawItem.VarDataDynamicTextType;
      this.Status = rawItem.Status;
      this.RawStatus = rawItem.RawStatus;
      this.Quantity = rawItem.Quantity;
      this.VarDataStatus = rawItem.VarDataStatus;
      this.CanImportData = rawItem.CanImportData;
      this.CanRollback = rawItem.CanRollback;
      this.NeedsCodes = rawItem.NeedsCodes;
      this.CanRequestCodes = rawItem.CanRequestCodes;
      this.HasSentErrorEmail = rawItem.HasSentErrorEmail;
      this.FulfillmentType = rawItem.FulfillmentType;
      this.TransparencyError = new TransparencyError(rawItem.TransparencyError);
      this.ProcessStatusCode = rawItem.ProcessStatusCode;
    }
  }
}
