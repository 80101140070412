export class TransparencyErrorModel {
  Ids: string[] = [];
}
export class TransparencyNeedCodesModel {
  Ids: string[] = [];
}
export class ProcessErrorReportModel {
  ReportDateTime!: Date;
  Report!: string;
  ErrorCount!: number;
  BarcodeConsumeId!: number;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Report = rawItem.Report;
      this.ReportDateTime = rawItem.ReportDateTime;
      this.ErrorCount = rawItem.ErrorCount;
      this.BarcodeConsumeId = rawItem.BarcodeConsumeId;
    }
  }
}
export class MultipleAmazonRequestModel {
  Requests: AmazonCodeRequestModel[] = [];
}
export class AmazonCodeRequestModel {
  OrderLineId!: string;
  RequestType!: number;
  NumberOfCodes!: number;
}
export class AmazonRequest {
  CodeRequestId!: string;
  IsComplete!: boolean;
  RequestStatus!: string;
  PollingCounter!: number;
  Gtin!: string;
  HasSentTransparencyErrorEmail!: boolean;
  RequestId!: string;
  NumberOfCodes!: number;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.CodeRequestId = rawItem.CodeRequestId;
      this.IsComplete = rawItem.IsComplete;
      this.RequestStatus = rawItem.RequestStatus;
      this.PollingCounter = rawItem.PollingCounter;
      this.Gtin = rawItem.Gtin;
      this.HasSentTransparencyErrorEmail = rawItem.HasSentTransparencyErrorEmail;
      this.RequestId = rawItem.RequestId;
      this.NumberOfCodes = rawItem.NumberOfCodes;
    }
  }
}
