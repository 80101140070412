
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import J1LMenu from '@/components/vuetifyOverrides/J1LMenu.vue';
import UploadVarDataDialog from '@/components/UploadVarDataDialog.vue';
import {
  CodeRequestModel,
  AdminCodeModel,
  TransparencyError
} from '@/store/models/CodeRequestModel';
import { VDataTableHeader } from '@/store/models/VDataTableModel';
import { MenuOption } from '@/store/models/MenuOptionModel';
import { AmazonAccessService } from '@/services/amazon-access-service';
import { SortArrayByProperty } from '@/helpers/ObjectHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import processErrorReportDialog from '@/components/ProcessErrorReportDialog.vue';
import AdminService from '@/services/admin-service';
import { CodeRequestInfo } from '@/store/models/ProfileModel';
import { Confirm } from '@/helpers/AuthHelper';
@Component({
  components: {
    DefaultLayout,
    J1LMenu,
    UploadVarDataDialog,
    processErrorReportDialog
  }
})
export default class extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
    UploadVarDataDialog: HTMLFormElement;
    fileUpload: HTMLFormElement;
    processErrorReportDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isGrabbingData: boolean = false;
  file: any = null;
  isUploadingCodesFile: boolean = false;
  hasError = false;
  filename: string = '';
  uploadPercentage = 0;
  selected: any[] = [];
  searchInput: string = '';
  shouldShowProcessErrors: boolean = false;
  ProcessErrorMessages: string = '';
  confirmItems: AdminCodeModel[] = [];
  customerOptions: string[] = [
    'Request Codes',
    'Reset Code Request',
    'Send Need Codes Transparency Email',
    'Send Transparency Error Email',
    'Remove Sent Request',
    'Initiate Data rollback',
    'Select All values for Customer'
  ];
  options: MenuOption[] = [
    {
      text: 'Upload Variable Data',
      isDisabled: function(item: AdminCodeModel) {
        //console.log(item);
        return !(
          item.CanImportData &&
          !item.TransparencyError.HasAnError &&
          (item.TransparencyError.FulfillmentType === 1 ||
            item.TransparencyError.FulfillmentType === 0)
        );
      },
      click: (item: AdminCodeModel) => {
        //console.log(item);
        this.$refs.UploadVarDataDialog.setData(item, true);
        this.$refs.UploadVarDataDialog.openDialog();
      }
    },
    {
      text: 'Initiate a data rollback',
      isDisabled: function(item: AdminCodeModel) {
        return !!item.CanImportData;
      },
      click: (item: AdminCodeModel) => {
        this.rollBackOrderlineConfirm(item);
      }
    },
    {
      text: 'View Error Report',
      isDisabled: function(item: AdminCodeModel) {
        return !(
          item.TransparencyError.HasAnError && item.TransparencyError.ErrorReportId != 0
        );
      },
      click: (item: AdminCodeModel) => {
        this.$refs.processErrorReportDialog.setData(item.TransparencyError.ErrorReportId);
        this.$refs.processErrorReportDialog.openDialog();
      }
    },
    {
      text: 'Request Codes',
      isDisabled: function(item: AdminCodeModel) {
        return !item.CanRequestCodes;
      },
      click: (item: AdminCodeModel) => {
        this.showRequestDialog(item);
        this.shouldShowItemInfoDialog = true;
      }
    },
    {
      text: 'Send Need Codes Email',
      isDisabled: function(item: AdminCodeModel) {
        return !(item.CanImportData && !item.CanRequestCodes);
      },
      click: (item: AdminCodeModel) => {
        this.sendNeedCodesConfirmationDlg(item);
        return false;
      }
    },
    {
      text: 'Send Transparency Error Email',
      isDisabled: function(item: AdminCodeModel) {
        return !(
          item.TransparencyError.HasAnError && item.TransparencyError.FulfillmentType == 1
        );
      },
      click: (item: AdminCodeModel) => {
        this.confirmItems = [item];
        this.sendCustomerErrorEmailConfirm(item);
      }
    },
    {
      text: 'Reset API Code request (Re-Request)',
      isDisabled: function(item: AdminCodeModel) {
        return !(
          item.TransparencyError.HasAnError && item.TransparencyError.ErrorType === 1
        );
      },
      click: (item: AdminCodeModel) => {
        this.resetCodeRequestConfirmationDlg(item);
      }
    },
    {
      text: 'Remove Amazon Request',
      isDisabled: function(item: AdminCodeModel) {
        return !(
          item.TransparencyError.HasAnError && item.TransparencyError.ErrorType === 1
        );
      },
      click: (item: AdminCodeModel) => {
        this.showRemoveAmazonRequestDialog(item);
      }
    },
    {
      text: 'Initiate Full Codes Rollback',
      isDisabled: function(item: AdminCodeModel) {
        return true;
      },
      click: function(item: AdminCodeModel) {
        return false;
      }
    },
    {
      text: 'Resend API Request to Barcode Processor',
      isDisabled: function(item: CodeRequestModel) {
        return !(item.HasStatusError && !item.HasFailedRequest);
      },
      click: function(item: CodeRequestModel) {
        return false;
      }
    },
    {
      text: 'Show Order Line Information',
      isDisabled: function(item: AdminCodeModel) {
        return false;
      },
      click: (item: AdminCodeModel) => {
        this.showInfoDialog(item.Id);
        return false;
      }
    },
    {
      text: 'Show Amazon API Error',
      isDisabled: function(item: AdminCodeModel) {
        return !(
          item.TransparencyError.HasAnError && item.TransparencyError.ErrorType === 1
        );
      },
      click: (item: AdminCodeModel) => {
        console.log('should be here.');
        this.getProcessErrors(item.Id);
      }
    }
  ];
  tableHeaders: VDataTableHeader[] = [
    {
      name: 'Customer',
      text: 'Customer',
      sortable: false,
      value: 'CustomerName'
    },
    {
      name: 'PartNumber',
      text: 'Item',
      sortable: false,
      value: 'PartNumber'
    },
    {
      name: 'Gtin',
      text: 'GTIN',
      sortable: false,
      value: 'Gtin'
    },
    {
      text: 'ASIN',
      sortable: false,
      value: 'Asin'
    },
    {
      text: 'SKU',
      sortable: false,
      value: 'Sku'
    },
    {
      text: 'Quantity',
      sortable: false,
      value: 'Quantity'
    },
    {
      text: 'Due Date',
      sortable: false,
      value: 'DueDate'
    },
    {
      text: 'Process Status',
      sortable: false,
      value: 'ProcessStatus'
    },
    {
      name: 'Options',
      text: 'Options',
      sortable: false,
      value: 'Options'
    }
  ];
  tableData: AdminCodeModel[] = [];
  customers: any[] = [];
  itemInformation: CodeRequestInfo = new CodeRequestInfo();
  selectedCustomer = '';
  processReportUrl!: string;
  shouldShowItemInfoDialog: boolean = false;
  /* Utility Functions */
  selectCustomer() {
    for (let td of this.tableData) {
      if (td.CustomerId === this.selectedCustomer) {
        this.selected.push(td);
      }
    }
  }
  showInfoDialog(orderlineId: string) {
    this.populateOrderlineInfo(orderlineId);
    this.shouldShowItemInfoDialog = true;
  }
  shouldDisplayProcessingChip(item: AdminCodeModel) {
    return !item.NeedsCodes && !item.TransparencyError.HasAnError;
  }
  getProcessingMessageForChip(item: AdminCodeModel) {
    switch (item.ProcessStatusCode) {
      case 0: {
        return 'Waiting On Code Importation';
      }
      case 1: {
        return 'Validating Data';
      }
      case 2: {
        return 'Importing Codes into System';
      }
      case 8: {
        return 'Finished Validation';
      }
      case 9: {
        return 'Finished Importation into System';
      }
      case 10:
      case 11: {
        return 'Codes Processed';
      }
    }
  }

  shouldDisplayWaitingOnCodes(item: AdminCodeModel) {
    return item.NeedsCodes;
  }
  tableDataByCustomer(customerName: string) {
    return this.tableData.filter(t => t.CustomerName === customerName);
  }
  sendErrorEmail() {
    return;
  }
  rollBackOrderlineConfirm(item: AdminCodeModel) {
    this.confirmItems = [item];
    Confirm(
      () => {
        this.rollBackRequest();
      },
      'Rollback Request',
      'Are you sure you want to roll back this request?  (All Data will be removed).'
    );
  }
  async rollBackRequest() {
    if (this.confirmItems[0]) {
      try {
        await AmazonAccessService.RollbackVarData(this.confirmItems[0].Id);
        SetSnackBar('This orderline has been rolled back.');
        this.loadTableData();
      } catch (error) {
        SetSnackBar('Something went terribly wrong.');
      }
    }
  }
  async populateOrderlineInfo(orderlineId: string) {
    try {
      const returnValue = await AdminService.getCodeManagerInfo(orderlineId);
      this.itemInformation = new CodeRequestInfo(returnValue.data);
    } catch (error) {
      SetSnackBar('Failed to get item information.');
    }
  }

  sendCustomerErrorEmailConfirm(item: AdminCodeModel, items: AdminCodeModel[] = null) {
    Confirm(
      () => {
        if (items) {
          this.confirmItems = items;
        } else {
          this.confirmItems = [item];
        }
        this.sendCustomerErrorEmail();
      },
      'Confirm sending email',
      'Are you sure you want to send error emails for this item(s)?'
    );
  }
  async getProcessErrors(orderLineId: string) {
    try {
      const { data } = await AmazonAccessService.GetAmazonProcessErrors(orderLineId);
      this.ProcessErrorMessages = data;
      this.shouldShowProcessErrors = true;
    } catch (error) {
      SetSnackBar('Failed to get errors, all your bases belong to me.');
    }
  }
  async sendCustomerErrorEmail() {
    if (this.confirmItems[0]) {
      let model = {
        Ids: this.confirmItems.map((i: any) => i.Id)
      };
      try {
        const returnValue = await AmazonAccessService.SendMultipleTransparencyErrorEmails(
          model
        );
        SetSnackBar('Your emails have been sent.');
      } catch (error) {
        SetSnackBar('Something Went terribly wrong.');
      }
      return;
    }
  }
  sendNeedCodesConfirmationDlg(item: AdminCodeModel) {
    this.confirmItems = [item];
    Confirm(
      () => {
        this.sendNeedCodesEmail();
      },
      'Confirm Send need codes',
      'Are you sure you want to send the need codes email?'
    );
  }
  async sendNeedCodesEmail() {
    if (this.confirmItems[0]) {
      let model = {
        Ids: this.confirmItems.map((i: any) => i.Id)
      };
      try {
        const returnValue = await AmazonAccessService.SendNeedCodesEmail(model);
        SetSnackBar('Your emails have been sent.');
      } catch (error) {
        SetSnackBar('Something Went terribly wrong.');
      }

      return;
    }
  }
  resetCodeRequestConfirmationDlg(item: AdminCodeModel) {
    this.confirmItems = [item];
    Confirm(
      () => {
        this.resetCodeRequest();
      },
      'Confirm code request reset',
      'Are you sure you want to send this FAILED code request again?  (All error reports will be lost).'
    );
  }
  async resetCodeRequest() {
    if (this.confirmItems[0]) {
      try {
        await AmazonAccessService.ResetCodeRequest(this.confirmItems[0].Id);
        this.loadTableData();
        SetSnackBar('Request has been reset.');
      } catch (error) {
        SetSnackBar('Something Went terribly wrong.');
      }
      return;
    }
  }
  showRequestDialog(item: AdminCodeModel) {
    this.confirmItems = [item];
    Confirm(
      () => {
        this.requestCodesForCustomer();
      },
      'Confirm code request',
      'Are you sure you want to request codes for this item(s)?'
    );
  }
  showRequestMultipleDialog(items: AdminCodeModel[]) {
    Confirm(
      () => {
        this.removeAmazonRequest();
      },
      'Remove Amazon Request',
      'Are you sure you want to REMOVE this request?'
    );
    this.confirmItems = items;
  }
  showRemoveAmazonRequestDialog(item: AdminCodeModel) {
    this.confirmItems = [item];
    Confirm(
      () => {
        this.removeAmazonRequest();
      },
      'Remove Amazon Request',
      'Are you sure you want to REMOVE this request'
    );
  }

  async removeAmazonRequest() {
    if (this.confirmItems[0]) {
      try {
        await AmazonAccessService.RemoveCodeRequest(this.confirmItems[0].Id);
        SetSnackBar('Your request has been removed.');
        this.loadTableData();
      } catch (error) {
        SetSnackBar('Your request has failed to be removed loser.');
      }
    }
  }

  async requestCodesForCustomer() {
    if (this.confirmItems[0]) {
      if (this.confirmItems.every(i => i.CanRequestCodes)) {
        let model = { Ids: this.confirmItems.map((i: any) => i.Id) };
        try {
          await AmazonAccessService.RequestCodesFromAmazon(model);
          this.loadTableData();
          SetSnackBar('Your codes have been successfully requested.');
        } catch (error) {
          SetSnackBar('You have failed, failed everybody.  Go seek help.');
        }
      }
    }
    return;
  }
  /* Loaders */
  /* Mounted */
  handleOptionClick({ option, target }: { option: string; target: CodeRequestModel }) {
    //console.log(target);
    switch (option) {
      case 'Upload Variable Data': {
        return;
      }
      case 'Initiate a data rollback': {
        return;
      }
      case 'View Error Report': {
        return;
      }
      case 'Request Codes': {
        return;
      }
    }
  }
  handleCustomerOptionClick(
    {
      option,
      target
    }: {
      option: string;
      target: any;
    },
    items: AdminCodeModel[]
  ) {
    //console.log(target);
    switch (option) {
      case 'Send Transparency Error Email': {
        //console.log('here');
        if (this.selected[0]) {
          let items = this.selected as AdminCodeModel[];
          let result = items.every(
            e =>
              e.TransparencyError.HasAnError &&
              e.TransparencyError.ErrorType === 1 &&
              !e.TransparencyError.HasSentErrorEmail
          );
          if (result) {
            this.sendCustomerErrorEmailConfirm(items[0], items);
          }
        }
        return;
      }
      case 'Initiate a data rollback': {
        return;
      }
      case 'View Error Report': {
        return;
      }
      case 'Request Codes': {
        this.showRequestMultipleDialog(this.selected);
        return;
      }
      case 'Select All values for Customer': {
        this.selected = items;
        return;
      }
    }
  }
  async loadTableData() {
    this.isGrabbingData = true;
    try {
      const { data } = await AmazonAccessService.GetOrderLinesForTransparencyAdmin();
      if (data.length > 0) {
        this.tableData = data.map((d: any) => new AdminCodeModel(d));
        this.tableData = SortArrayByProperty(this.tableData, 'CustomerName');
        this.customers = [
          ...this.tableData.map(d => ({
            CustomerName: d.CustomerName,
            CanRequestCodes: d.CanRequestCodes,
            ShouldExpand: false
          }))
        ].filter((v, i, a) => a.findIndex(t => t.CustomerName === v.CustomerName) === i);
      }
    } catch (err) {
      SetSnackBar('Error loading code requests');
    }
    this.isGrabbingData = false;
  }
  async mounted() {
    this.shouldShowItemInfoDialog = false;
    await this.loadTableData();
  }
  /* Created */
  /* Emmited Functions */
}
