
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { ValidationHelper } from '@/helpers/ValidationHelper';
import { AmazonAccessService } from '@/services/amazon-access-service';
import { CodeRequestModel, SubmitCodesModel } from '@/store/models/CodeRequestModel';
import { Orderline } from '@/store/models/OrderlineModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class UploadVarDataDialog extends Vue {
  $refs!: {
    FileUpload: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  hasErrorUploadingFile: boolean = false;
  hasError: boolean = false;
  isBringingUpFileDisplay: boolean = false;
  isLoading: boolean = false;
  isUploadingItemFile: boolean = false;
  isSubmitting: boolean = false;
  dialog: boolean = false;
  codeRequest: CodeRequestModel = new CodeRequestModel();
  originalFileName: string = '';
  isValidatingCodes: boolean = false;
  consumeId: number = 0;
  canSubmitCodes: boolean = false;
  file: any = null;
  fileDescription: string = '';
  filename: string = '';
  itemSrc: string = '';
  errorReportFileName: string = '';
  uploadPercentage: number = 0;
  shouldUseFileDelay: boolean = false;
  fileProcessDelayInMinutes: number = 60;
  isAdminRequest: boolean = false;
  /* Async Functions */
  async uploadFile(val: any) {
    let that = this;
    this.hasErrorUploadingFile = false;
    if (val && val.name) {
      const fileExtension = val.name
        .split('.')
        .pop()
        .toLowerCase();
      if (fileExtension !== 'csv') {
        SetSnackBar('Please upload a CSV file.');
        this.file = null;
        return;
      }
      this.file = val;
      if (!this.fileDescription) this.fileDescription = this.file.name;
      this.isUploadingItemFile = true;
      this.itemSrc = '';
      let formData = new FormData();
      formData.append('files[]', val, val.name);
      this.isUploadingItemFile = true;
      try {
        const response = await AmazonAccessService.UploadCodeFile(formData, {
          onUploadProgress: function(progressEvent: any) {
            that.uploadPercentage = Math.round(
              (parseInt(progressEvent.loaded) * 100) / parseInt(progressEvent.total)
            );
          }.bind(this)
        });
        if (response.data.result === 'error') {
          SetSnackBar('Something went wrong updating your file');
          this.hasErrorUploadingFile = true;
          this.isUploadingItemFile = false;
          this.itemSrc = '';
        }
        this.filename = response.data.fileName;
        this.originalFileName = response.data.originalFileName;

        const validateResponse = await AmazonAccessService.ValidateCodes(
          this.filename,
          this.codeRequest.Quantity,
          this.originalFileName,
          this.codeRequest.CustomerId || ''
        );
        if (validateResponse.data.hasAnError) {
          this.hasError = true;
          this.errorReportFileName = validateResponse.data.errorFileName;
        } else {
          this.canSubmitCodes = true;
          this.consumeId = validateResponse.data.consumeId;
        }
        this.isUploadingItemFile = false;
      } catch {
        this.hasErrorUploadingFile = true;
        this.isUploadingItemFile = false;
        this.itemSrc = '';
        this.canSubmitCodes = false;
        SetSnackBar('Something went wrong updating your file');
        return;
      }
      this.isUploadingItemFile = false;
    }
  }
  /* Utility Functions */
  canSubmit() {
    return this.canSubmitCodes && !this.hasError;
  }
  handleCodeValidationResponse(response: any) {
    if (response.hasError) {
      SetSnackBar(
        'Something went wrong with the validation of your codes.  See the report to see the problem.'
      );
      return;
    } else {
      console.log('Add codes!');
      return;
    }
  }
  submitCodes() {
    try {
      this.isSubmitting = true;
      let model = new SubmitCodesModel();
      model.ConsumeId = this.consumeId;
      model.OrderLineId = this.codeRequest.Id;
      model.ShouldDelayProcess = this.shouldUseFileDelay;
      model.ProcessDelayInMinutes = this.fileProcessDelayInMinutes;
      AmazonAccessService.SaveBarcodes(model).then(() => {
        this.isSubmitting = false;
        this.$emit(
          'refreshOrderline',
          this.codeRequest.OriginalOrderline,
          'Codes have been submitted.'
        );
        this.closeDialog();
      });
    } catch (error) {
      SetSnackBar('Something went wrong with saving your codes.');
      this.closeDialog();
    }
  }
  setData(params: any, isAdmin: boolean = false) {
    console.log(params);
    this.codeRequest = params;
    this.isAdminRequest = isAdmin;
  }
  openDialog() {
    this.dialog = true;
  }
  closeDialog() {
    this.resetData();
    this.refreshTableData();
    this.dialog = false;
  }
  resetData() {
    this.isLoading = false;
    this.hasErrorUploadingFile = false;
    this.filename = '';
    this.file = null;
    this.isUploadingItemFile = false;
    this.isValidatingCodes = false;
    this.hasError = false;
    this.consumeId = 0;
    this.originalFileName = '';
    this.isSubmitting = false;
    this.isAdminRequest = false;
    this.shouldUseFileDelay = false;
  }
  uploadDropzoneFile(e: any) {
    this.file = e.dataTransfer.files[0];
  }
  refreshTableData() {
    this.$emit('refreshData');
  }
  onFileUploadClick() {
    this.fileDescription = '';
    this.filename = '';
    this.$refs.FileUpload.$refs.input.click();
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
